@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: #fff;
  color: #000;
}
.spinner{
  height: 3rem;
  width: 3rem;
  border: 1px solid black;
  border-radius: 50%;
  border-left: none;
  border-bottom: none;
  background-color: transparent;
  animation: spinner 1s infinite ease-out;
  position: fixed;
  top: 5%;
  left: 45%;
}
@keyframes spinner {
  from{
      transform: rotate(0deg);
  }
  to{
      transform: rotate(360deg);
  }
}